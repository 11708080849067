import React from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import info from "../../../assets/infoOutline.png";
import map from "../../../assets/map.png";
import location from "../../../assets/location_icon.png";
import bedroom from "../../../assets/bedroom_icon.png";
import car from "../../../assets/car_icon.png";
import shower from "../../../assets/shower_icon.png";
import property1 from "../../../assets/property-1.png";
import property2 from "../../../assets/property-2.png";
import ag1 from "../../../assets/ag1.png";
import agent1 from "../../../assets/agent-1.png";
import agent2 from "../../../assets/agent-2.png";
import Googlemap from "./Googlemap";

const PropertiesCard = ({ AgencyData }) => {
  const navigate = useNavigate();

  const PropertyData = [
    {
      id: "13580aveyu297nxb",
      iconTop: property1,
      agencyImg: ag1,
      agentImg: agent1,
      agentName: "Josh Finch",
      title: "$520,000",
      address: "40/28 Fortune Street Coomera, Qld",
      bedroomCount: "3",
      showerCount: "2",
      carCount: "2",
      house: "Townhouse",
      saleDate: "Sold on 03 Nov 2022",
    },
    {
      id: "13580rtretreaveyu297nxb",
      iconTop: property2,
      agencyImg: ag1,
      agentImg: agent2,
      agentName: "Patrick Donald",
      title: "$724,000",
      address: "25 Runway Drive Upper Coomera, Qld",
      bedroomCount: "4",
      showerCount: "2",
      carCount: "2",
      house: "House",
      saleDate: "Sold on 01 Nov 2022",
    },
    {
      id: "135fdsfd80aveyu297nxb",
      iconTop: property1,
      agencyImg: ag1,
      agentImg: agent2,
      agentName: "Patrick Donald",
      title: "$495,000",
      address: "37/2 Weir Drive Upper Coomera, Qld",
      bedroomCount: "3",
      showerCount: "2",
      carCount: "2",
      house: "Townhouse",
      saleDate: "Sold on 31 Oct 2022",
    },
  ];

  return (
    <div className="flex flex-col justify-center items-start bg-[#FFFFFF] rounded-xl shadow-md hover:shadow-lg p-4 md:p-6">
      <div className="w-full flex justify-between sm:justify-start items-center gap-3">
        <div className="text-[#171717] font-bold text-base md:text-lg lg:text-xl">
          Our properties
        </div>
        <img src={info} alt="icon" className="w-4 cursor-pointer" />
      </div>
      <div className="text-[#404040] font-medium text-sm md:text-sm lg:text-base mt-2">
        {AgencyData?.principal_name} have sold 313 properties of all time on
        realestate.com.au and have 14 properties for sale.
      </div>
      {/* --------- Sort By --------- */}
      <div className="w-full flex flex-col md:flex-row justify-center gap-4 my-4">
        <div className="w-full">
          <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
            Sort by
            <span className="px-1 text-red-500">*</span>
          </div>
          <select
            name="select"
            className="round w-full !text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 cursor-pointer mt-2"
          >
            <option value="">Sold</option>
            <option value="">Buy</option>
            <option value="">Rent</option>
            <option value="">Sold</option>
          </select>
        </div>
        <div className="w-full">
          <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
            Sort by <span className="px-1 text-red-500">*</span>
          </div>
          <select
            name="select"
            className="round w-full !text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 cursor-pointer mt-2"
          >
            <option value="">12 months</option>
            <option value="">3 months</option>
            <option value="">6 months</option>
            <option value="">12 months</option>
          </select>
        </div>
      </div>

      {/* -------- Map -------- */}
      <Googlemap />
      {/* <LazyLoadImage
        src={map}
        alt="icon"
        srcSet={map}
        loading="lazy"
        effect="blur"
        className="mt-4 cursor-pointer"
      /> */}

      {/* -------- property Cards -------- */}
      <div className="text-[#404040] font-medium text-sm md:text-sm lg:text-base mt-4">
        Showing 3 of 139 properties sold on realestate.com.au in the last 12
        months
      </div>

      <div className="grid place-items-start grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-4 md:mt-6">
        {PropertyData?.length > 0 &&
          PropertyData?.map((i, index) => (
            <div
              key={index}
              className="flex flex-col bg-white rounded-lg shadow-md hover:shadow-lg cursor-pointer p-2"
              onClick={() => navigate(`/property-house/${i?.id}`)}
            >
              <div className="grid">
                <LazyLoadImage
                  src={i?.iconTop}
                  alt="icon"
                  srcSet={i?.iconTop}
                  loading="lazy"
                  effect="blur"
                  className="rounded-t-lg"
                />
                <div className="bg-[#FFE815] flex justify-between items-center gap-2 rounded-b-lg p-3">
                  <LazyLoadImage
                    src={i?.agencyImg}
                    alt="icon"
                    srcSet={i?.agencyImg}
                    loading="lazy"
                    effect="blur"
                    className="w-20 md:w-24"
                  />
                  <div className="flex justify-center items-center text-[#404040] font-medium text-xs md:text-sm rounded-b-lg">
                    <LazyLoadImage
                      src={i?.agentImg}
                      alt="icon"
                      srcSet={i?.agentImg}
                      loading="lazy"
                      effect="blur"
                      className="w-6 md:w-8 mr-2"
                    />
                    {i?.agentName}
                  </div>
                </div>
              </div>
              <div className="mx-4">
                <div className="flex justify-start items-center mt-5 md:mt-6">
                  <div className="font-extrabold text-[#404040] text-base md:text-lg lg:text-xl ">
                    {i?.title}
                  </div>
                </div>

                <div className="flex justify-start items-start gap-2 mt-2 md:mt-3">
                  <img src={location} alt="location" className="w-4 md:w-5" />
                  <div className="font-semibold text-[#737373] text-xs md:text-sm">
                    {i?.address}
                  </div>
                </div>

                <div className="flex flex-wrap justify-start items-center gap-2 my-5">
                  <div className="flex justify-center items-center gap-2 bg-[#F5F5F5] rounded-md p-2">
                    <img src={bedroom} alt="location" className="w-4 md:w-5" />
                    <div className="font-semibold text-[#737373] text-xs md:text-sm">
                      {i?.bedroomCount}
                    </div>
                  </div>
                  <div className="flex justify-center items-center gap-2 bg-[#F5F5F5] rounded-md p-2">
                    <img src={shower} alt="bedroom" className="w-4 md:w-5" />
                    <div className="font-semibold text-[#404040] text-xs md:text-sm">
                      {i?.showerCount}
                    </div>
                  </div>
                  <div className="flex justify-center items-center gap-2 bg-[#F5F5F5] rounded-md p-2">
                    <img src={car} alt="shower" className="w-4 md:w-5" />
                    <div className="font-semibold text-[#404040] text-xs md:text-sm">
                      {i?.carCount}
                    </div>
                  </div>
                  <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                    <div className="font-semibold text-[#404040] text-xs md:text-sm">
                      {i?.house}
                    </div>
                  </div>
                </div>

                <div className="text-start font-medium text-[#525252] text-xs md:text-sm my-3">
                  {i?.saleDate}
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* -------- button -------- */}
      <button className="w-full text-xs md:text-sm lg:text-base hover:font-semibold font-medium border bg-[#E5002A] text-white hover:bg-white  hover:text-[#E5002A] hover:border-[#E5002A] py-3 px-5 rounded-3xl mt-4 md:mt-6">
        Show more properties
      </button>
    </div>
  );
};

export default PropertiesCard;
