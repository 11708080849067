import React from "react";
import GoogleMapReact from "google-map-react";

const Googlemap = () => {
  const defaultProps = {
    center: {
      lat: 48.210033,
      lng: 16.363449,
    },
    zoom: 11,
  };
  return (
    <div style={{ height: "60vh", width: "100%" }}>
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCLI0RcKm3l0m7EAcx3H1sYjzbybgGKk4E" }}
        defaultCenter={defaultProps?.center}
        defaultZoom={defaultProps?.zoom}
      ></GoogleMapReact> */}
    </div>
  );
};

export default Googlemap;
