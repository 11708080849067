import React from "react";
import { useNavigate } from "react-router-dom";
import { rightSideBlueArrow, searchGray } from "../../assets";
import Layout1 from "../../Layouts/Layout1";

const Marketing = () => {
  const navigate = useNavigate();

  const MarketingData = [
    "Overview",
    "Premiere Property",
    "Highlight property",
    "Feature Property",
    "Exclusive Showcase",
    "Agent banner Advertising",
    "Feature Agent",
    "eBrochure",
    "Embedded Video",
  ];

  const MarketingOverview = [
    {
      title: "Premiere property",
      description:
        "Add Prestig and prominece to your property with the highest priority positioning above all other ads, for Increased exposure and superior brand awareness.  ",
      link: "",
    },
    {
      title: "Highlight Property",
      description:
        "An attention-grabbing design and larger photos ensure your property is more visible, while receiving priority positioning above all Feature Property and Standard ads.",
      link: "",
    },
    {
      title: "Feature Property",
      description:
        "Stand out above Standard ads while increasing exposure and engagement with your property and brand.",
      link: "",
    },
    {
      title: "Exclusive Showcase",
      description:
        "Be prominent in your suburb, with prime positioning on every search results page and use this exclusive product in listing presentations to differentiate your agency and win new listings.",
      link: "",
    },
    {
      title: "Agent Banner Advertising",
      description:
        "A unique opportunity to promote your agency in your local market. Branded with your colours, message and logo, own the top and side banners on the search results page in your purchased suburb.",
      link: "",
    },
    {
      title: "Feature Agent",
      description:
        "Maximise your brand's exposure and position yourself as a leader in your local marketplace. Use your priority positioning above Standard agent listings to win new vendors.",
      link: "",
    },
    {
      title: "eBrochure",
      description:
        "A cost-effective, highly targeted solution that allows you to successfully capture leads and drive more enquiry, as it's emailed to a captive, interested audience. Use as a tool to notify potential buyers of OFIS, up and coming auctions or price changes to reinvigorate campaigns and buyer interest.",
      link: "",
    },
  ];
  return (
    <Layout1>
      <div className="container mx-auto px-5 xl:px-0">
        {/* ---------- section 1  ---------- */}
        {/* <div className="w-full flex flex-col 2xl:flex-row  justify-start gap-3 bg-white rounded-2xl shadow-md text-[#404040] font-medium p-6">
          <div className="w-full 2xl:w-[60%] flex flex-col md:flex-row justify-start items-center gap-3">
            <div className="w-full flex justify-start items-center gap-2 border border-[#E5E5E5] rounded-3xl py-3 px-5 cursor-pointer">
              <img src={searchGray} alt="icon" className="w-3 lg:w-4" />
              <input
                type="text"
                placeholder="Search"
                className="w-full text-[#A3A3A3]  text-xs md:text-sm outline-none"
              />
            </div>
            <select
              name="select"
              className="round w-full !text-[#404040] text-xs md:text-sm outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 cursor-pointer"
            >
              <option value="">Select a property type</option>
              <option value="">Acreage/Semi-Rural</option>
              <option value="">Alpine</option>
              <option value="">Apartment</option>
              <option value="">Block Of Units</option>
              <option value="">Duplex/Semi-detached</option>
              <option value="">Flat</option>
              <option value="">House</option>
              <option value="">Retirement Living</option>
              <option value="">Serviced Apartment</option>
              <option value="">Studio</option>
              <option value="">Terrace</option>
              <option value="">Townhouse</option>
              <option value="">Unit</option>
              <option value="">Villa</option>
              <option value="">Warehouse</option>
              <option value="">Other</option>
            </select>
          </div>
          <div className="w-full 2xl:w-[40%] flex  flex-col sm:flex-row justify-end 2xl:justify-center gap-2">
            <div className="flex flex-row justify-end gap-3">
              <div
                className="border border-[#E5E5E5] rounded-3xl py-3 px-5 text-xs md:text-sm  cursor-pointer"
                onClick={() => navigate(`/listings/add/1`)}
              >
                Add Listing
              </div>
              <div className="border border-[#E5E5E5] rounded-3xl py-3 px-5 text-xs md:text-sm cursor-pointer">
                View Stocklist
              </div>
            </div>
            <div className="flex flex-row justify-end gap-3">
              <div className="border border-[#E5E5E5] rounded-3xl py-3 px-5 text-xs md:text-sm cursor-pointer">
                Sold
              </div>
              <div className="border border-[#E5E5E5] rounded-3xl py-3 px-5 text-xs md:text-sm cursor-pointer">
                Leased
              </div>
            </div>
          </div>
        </div> */}

        {/* ---------- section 2  ---------- */}

        <div className="w-full flex flex-col-reverse xl:flex-row justify-center items-start gap-8 my-10">
          {/* --------- Left Part --------- */}

          <div className="w-full xl:w-[75%]  bg-white rounded-xl">
            <div className="bg-[#E5002A] text-white font-semibold text-sm md:text-base lg:text-lg rounded-t-xl p-5">
              Marketing center- overview
            </div>

            <div className="h-full grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 justify-center items-center gap-5 p-5">
              {MarketingOverview?.length > 0 &&
                MarketingOverview?.map((i, index) => (
                  <div
                    key={index}
                    className="h-full flex flex-col justify-between items-start border-2 border-[#F5F5F5] rounded-xl text-[#404040] text-sm md:text-sm lg:text-base py-4 px-5 "
                  >
                    <div>
                      <div className="text-black font-bold text-base md:text-lg">
                        {i?.title}
                      </div>
                      <div className="text-[#737373] font-medium text-sm md:text-sm py-3">
                        {i?.description}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-2 cursor-pointer">
                      <div className="text-[#3B8FD4] font-medium text-sm md:text-sm py-2">
                        Learn More
                      </div>
                      <img
                        src={rightSideBlueArrow}
                        alt="icon"
                        className="w-4"
                      />
                    </div>
                  </div>
                ))}
            </div>

            <div className="flex justify-start items-center border-2 border-[#F5F5F5] rounded-xl text-[#262626] font-bold text-lg md:text-xl lg:text-2xl mx-5 mb-5 p-5">
              Marketing Centre - Overview
            </div>
          </div>

          {/* --------- Right Part --------- */}

          <div className="w-full xl:w-[25%] bg-white rounded-xl">
            <div className="bg-[#E5002A] text-white font-semibold text-sm md:text-base lg:text-lg rounded-t-xl p-5">
              Marketing center
            </div>
            {MarketingData?.length > 0 &&
              MarketingData?.map((i, index) => (
                <div
                  key={index}
                  className="border-b-2 border-[#F5F5F5] text-[#404040] text-sm md:text-sm lg:text-base py-4 px-5 "
                >
                  {i}
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout1>
  );
};

export default Marketing;
