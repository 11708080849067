// import React, { useState, useEffect, useRef } from "react";

// let autoComplete;

// const loadScript = (url, callback) => {
//   let script = document.createElement("script");
//   script.type = "text/javascript";

//   if (script.readyState) {
//     script.onreadystatechange = function () {
//       if (script.readyState === "loaded" || script.readyState === "complete") {
//         script.onreadystatechange = null;
//         callback();
//       }
//     };
//   } else {
//     script.onload = () => callback();
//   }

//   script.src = url;
//   document.getElementsByTagName("head")[0].appendChild(script);
// };

// function handleScriptLoad(updateQuery, autoCompleteRef) {
//   autoComplete = new window.google.maps.places.Autocomplete(
//     autoCompleteRef.current,
//     {
//       types: ["postal_code"],
//       componentRestrictions: { country: ["CO", "IN"] },
//     }
//   );
//   autoComplete.setFields(["address_components", "formatted_address"]);
//   autoComplete.addListener("place_changed", () =>
//     handlePlaceSelect(updateQuery)
//   );
// }

// async function handlePlaceSelect(updateQuery) {
//   const addressObject = autoComplete.getPlace();
//   const query = addressObject.formatted_address;
//   updateQuery(query);
//   console.log(addressObject);
// }

// function SearchLocationInput(props) {
//   const [query, setQuery] = useState("");
//   const autoCompleteRef = useRef(null);

//   useEffect(() => {
//     loadScript(
//       `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
//       () => handleScriptLoad(setQuery, autoCompleteRef)
//     );
//   }, []);

//   return (
//     <div className="search-location-input">
//       <input
//         ref={autoCompleteRef}
//         onChange={(event) => setQuery(event.target.value)}
//         placeholder={props.placeholder}
//         value={query}
//         className={props.className}
//       />
//     </div>
//   );
// }

// export default SearchLocationInput;
import React from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
const placesLibrary = ["places"];

const SearchLocationInput = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBF9EWb8h108q71fTe2wOw7ixi0id3PKA0",
    libraries: placesLibrary,
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Autocomplete onPlaceChanged={props.onPlaceChanged} onLoad={props.onLoad}>
        <div>
          <input placeholder="Search…" className={props.className} />
        </div>
      </Autocomplete>
    </>
  );
};

export default SearchLocationInput;
