import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { questionMark, searchGray } from "../../assets";
import Layout1 from "../../Layouts/Layout1";

const AgencyProfile = () => {
  const navigate = useNavigate();
  const Role = localStorage.getItem("role");
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    GetAgencyProfile();
  }, []);

  const GetAgencyProfile = async () => {
    await axiosInstanceAuth
      .post(`agency/ViewProfile`, { role: Role })
      .then((res) => {
        const mydata = res?.data?.data;
        if (res?.data?.status) {
          setAgencyProfileDetails(mydata);
        } else {
          // toast.error("Oops! Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const EditAgencyProfile = async () => {
    if (AgencyProfileDetails?.street === "") {
      toast.error("Please enter Street");
    } else if (AgencyProfileDetails?.suburb_area === "") {
      toast.error("Please enter a Suburb Area");
    } else if (AgencyProfileDetails?.postcode === "") {
      toast.error("Please enter a Postcode");
    } else if (AgencyProfileDetails?.state_region === "") {
      toast.error("Please enter a State Region");
    } else if (AgencyProfileDetails?.suburb_area === "") {
      toast.error("Please enter a Country");
    } else if (AgencyProfileDetails?.country === "") {
      toast.error("Please enter a Suburb Area");
    } else if (AgencyProfileDetails?.mailing_address_street === "") {
      toast.error("Please enter a Mailing Address Street");
    } else if (AgencyProfileDetails?.mailing_address_suburb_area === "") {
      toast.error("Please enter a Mailing Address Suburb Area");
    } else if (AgencyProfileDetails?.mailing_address_postcode === "") {
      toast.error("Please enter a Mailing Address Postcode");
    } else if (AgencyProfileDetails?.mailing_address_state_region === "") {
      toast.error("Please enter a Mailing Address State Region");
    } else if (AgencyProfileDetails?.mailing_address_country === "") {
      toast.error("Please enter a Mailing Address Country");
    } else if (AgencyProfileDetails?.fax === "") {
      toast.error("Please enter a Fax");
    } else if (AgencyProfileDetails?.phone === "") {
      toast.error("Please enter a Phone");
    }
    // else if (AgencyProfileDetails?.web === "") {
    //   toast.error("Please enter a Web");
    // } else if (AgencyProfileDetails?.facebook_page === "") {
    //   toast.error("Please enter a Facebook Page URL");
    // } else if (AgencyProfileDetails?.twitter_profile_url === "") {
    //   toast.error("Please enter a Twitter Profile URL");
    // }
    else if (AgencyProfileDetails?.principal_name === "") {
      toast.error("Please enter a Principal Name");
    } else if (AgencyProfileDetails?.display_email === "") {
      toast.error("Please enter a valid Display Email");
    } else if (AgencyProfileDetails?.office_description === "") {
      toast.error("Please enter a Office Description");
    } else if (!emailRegex.test(AgencyProfileDetails?.email)) {
      toast.error("Please enter a valid Email");
    } else {
      await axiosInstanceAuth
        .post("agency/UpdateProfile", {
          street: AgencyProfileDetails?.street,
          suburb_area: AgencyProfileDetails?.suburb_area,
          postcode: AgencyProfileDetails?.postcode,
          state_region: AgencyProfileDetails?.state_region,
          country: AgencyProfileDetails?.country,
          mailing_address_street: AgencyProfileDetails?.mailing_address_street,
          mailing_address_suburb_area:
            AgencyProfileDetails?.mailing_address_suburb_area,
          mailing_address_postcode:
            AgencyProfileDetails?.mailing_address_postcode,
          mailing_address_state_region:
            AgencyProfileDetails?.mailing_address_state_region,
          mailing_address_country:
            AgencyProfileDetails?.mailing_address_country,
          fax: AgencyProfileDetails?.fax,
          phone: AgencyProfileDetails?.phone,
          email: AgencyProfileDetails?.email,
          web: AgencyProfileDetails?.web,
          facebook_page: AgencyProfileDetails?.facebook_page,
          twitter_profile_url: AgencyProfileDetails?.twitter_profile_url,
          principal_name: AgencyProfileDetails?.principal_name,
          display_email: AgencyProfileDetails?.display_email,
          office_description: AgencyProfileDetails?.office_description,
        })
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.message);
            navigate(`/`);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log("err --->", err);
        });
    }
  };

  const [textAreaLength, setTextAreaLength] = useState();
  const [AgencyProfileDetails, setAgencyProfileDetails] = useState({
    street: "",
    suburb_area: "",
    postcode: "",
    state_region: "",
    country: "",
    mailing_address_street: "",
    mailing_address_suburb_area: "",
    mailing_address_postcode: "",
    mailing_address_state_region: "",
    mailing_address_country: "",
    fax: "",
    phone: "",
    email: "",
    web: "",
    facebook_page: "",
    twitter_profile_url: "",
    principal_name: "",
    display_email: "",
    office_description: "",
  });

  const ProfileData = [
    "Overview",
    "Listing Expense Report",
    "Agents",
    "Suburb & Municipality List",
    "Branding",
    "Sales Representatives",
    "Agency Profile",
    "Servicing Suburbs",
  ];

  const onInputChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "checkAbove") {
      if (checked) {
        setAgencyProfileDetails({
          ...AgencyProfileDetails,
          mailing_address_street: AgencyProfileDetails.street,
          mailing_address_suburb_area: AgencyProfileDetails.suburb_area,
          mailing_address_postcode: AgencyProfileDetails.postcode,
          mailing_address_state_region: AgencyProfileDetails.state_region,
          mailing_address_country: AgencyProfileDetails.country,
          [name]: value,
        });
      } else {
        setAgencyProfileDetails({
          ...AgencyProfileDetails,
          mailing_address_street: "",
          mailing_address_suburb_area: "",
          mailing_address_postcode: "",
          mailing_address_state_region: "",
          mailing_address_country: "",
          [name]: value,
        });
      }
    } else {
      const updatedDetails = { ...AgencyProfileDetails, [name]: value };
      if (AgencyProfileDetails.checkAbove) {
        if (name === "street") {
          updatedDetails.mailing_address_street = value;
        } else if (name === "suburb_area") {
          updatedDetails.mailing_address_suburb_area = value;
        } else if (name === "postcode") {
          updatedDetails.mailing_address_postcode = value;
        } else if (name === "state_region") {
          updatedDetails.mailing_address_state_region = value;
        } else if (name === "country") {
          updatedDetails.mailing_address_country = value;
        }
      }
      setAgencyProfileDetails(updatedDetails);
    }
  };

  return (
    <Layout1>
      <div className="container mx-auto px-5 xl:px-0">
        {/* ---------- section 1  ---------- */}
        <div className="w-full flex flex-col 2xl:flex-row  justify-start gap-3 bg-white rounded-2xl shadow-md text-[#404040] font-medium p-6">
          <div className="w-full 2xl:w-[60%] flex flex-col md:flex-row justify-start items-center gap-3">
            <div className="w-full flex justify-start items-center gap-2 border border-[#E5E5E5] rounded-3xl py-3 px-5 cursor-pointer">
              <img src={searchGray} alt="icon" className="w-3 lg:w-4" />
              <input
                type="text"
                placeholder="Search"
                className="w-full text-[#A3A3A3]  text-xs md:text-sm outline-none"
              />
            </div>
            <select
              name="select"
              className="round w-full !text-[#404040] text-xs md:text-sm outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 cursor-pointer"
            >
              <option value="">Select a property type</option>
              <option value="">Select a property type</option>
              <option value="">Acreage/Semi-Rural</option>
              <option value="">Alpine</option>
              <option value="">Apartment</option>
              <option value="">Block Of Units</option>
              <option value="">Duplex/Semi-detached</option>
              <option value="">Flat</option>
              <option value="">House</option>
              <option value="">Retirement Living</option>
              <option value="">Serviced Apartment</option>
              <option value="">Studio</option>
              <option value="">Terrace</option>
              <option value="">Townhouse</option>
              <option value="">Unit</option>
              <option value="">Villa</option>
              <option value="">Warehouse</option>
              <option value="">Other</option>
            </select>
          </div>
          <div className="w-full 2xl:w-[40%] flex  flex-col sm:flex-row justify-end 2xl:justify-center gap-2">
            <div className="flex flex-row justify-end gap-3">
              <div
                className="border border-[#E5E5E5] rounded-3xl py-3 px-5 text-xs md:text-sm  cursor-pointer"
                onClick={() => navigate(`/listings/add/1`)}
              >
                Add Listing
              </div>
              <div className="border border-[#E5E5E5] rounded-3xl py-3 px-5 text-xs md:text-sm cursor-pointer">
                View Stocklist
              </div>
            </div>
            <div className="flex flex-row justify-end gap-3">
              <div className="border border-[#E5E5E5] rounded-3xl py-3 px-5 text-xs md:text-sm cursor-pointer">
                Sold
              </div>
              <div className="border border-[#E5E5E5] rounded-3xl py-3 px-5 text-xs md:text-sm cursor-pointer">
                Leased
              </div>
            </div>
          </div>
        </div>

        {/* ---------- section 2  ---------- */}

        <div className="w-full flex flex-col-reverse xl:flex-row justify-center items-start gap-8 my-10">
          {/* --------- Left Part --------- */}

          <div className="w-full xl:w-[75%] bg-white rounded-xl">
            <div className="bg-[#E5002A] text-white font-semibold text-sm md:text-base lg:text-lg rounded-t-xl p-5">
              Your Profile - Agency Profile
            </div>

            <div className="p-6">
              <div className="text-black font-semibold text-sm md:text-base lg:text-lg my-4 mb-8">
                Mandatory fields are marked with an asterisk (
                <span className="text-[#E5002A]">*</span>)
              </div>
              {/* -------- Address -------- */}
              <div className="font-semibold text-[#E5002A] my-5 md:my-8">
                Address
              </div>
              <div className="">
                <div className="font-medium text-[#171717] text-xs md:text-sm">
                  Street : <span className="px-1 text-[#E5002A]">*</span>
                </div>
                <input
                  type="text"
                  value={AgencyProfileDetails?.street}
                  name="street"
                  onChange={onInputChange}
                  placeholder="Enter street"
                  className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-3"
                />
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-4 mt-4 md:mt-6">
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm ">
                    Suburb/Area :<span className="px-1 text-red-500">*</span>
                  </div>

                  <input
                    type="text"
                    value={AgencyProfileDetails?.suburb_area}
                    name="suburb_area"
                    onChange={onInputChange}
                    placeholder="Enter suburb area"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                </div>
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm">
                    Postcode :<span className="px-1 text-red-500">*</span>
                  </div>
                  <input
                    type="number"
                    value={AgencyProfileDetails?.postcode}
                    name="postcode"
                    onChange={onInputChange}
                    placeholder="Enter postcode"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-4 mt-4 md:mt-6">
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm ">
                    State/Region :<span className="px-1 text-red-500">*</span>
                  </div>
                  <select
                    name="state_region"
                    value={AgencyProfileDetails?.state_region}
                    onChange={onInputChange}
                    className="round w-full font-medium !text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  >
                    <option value="">Select state / region</option>
                    <option value="Bogotá">Bogotá</option>
                    <option value="Antioquia">Antioquia</option>
                    {/* <option value="Western Australia">Western Australia</option> */}
                    <option value="Valle del Cauca">Valle del Cauca</option>
                    <option value="Cundinamarca">Cundinamarca</option>
                    <option value="Atlántico">Atlántico</option>
                    <option value="Santander">Santander</option>
                    <option value="Bolívar">Bolívar</option>
                    <option value="Nariño">Nariño</option>
                    <option value="Córdoba">Córdoba</option>
                    <option value="Tolima">Tolima</option>
                    <option value="Cauca">Cauca</option>
                    <option value="Norte de Santander">
                      Norte de Santander
                    </option>
                    <option value="Boyacá">Boyacá</option>
                    <option value="Magdalena">Magdalena</option>
                    <option value="Huila">Huila</option>
                    <option value="Cesar">Cesar</option>
                    <option value="Caldas">Caldas</option>
                    <option value="Meta">Meta</option>
                    <option value="La Guajira">La Guajira</option>
                    <option value="Risaralda">Risaralda</option>
                    <option value="Sucre">Sucre</option>
                    <option value="Quindío">Quindío</option>
                    <option value="Chocó">Chocó</option>
                    <option value="Caquetá">Caquetá</option>
                    <option value="Casanare">Casanare</option>
                    <option value="Putumayo">Putumayo</option>
                    <option value="Arauca">Arauca</option>
                    <option value="Guaviare">Guaviare</option>
                    <option value="San Andrés y Providencia">
                      San Andrés y Providencia
                    </option>
                    <option value="Amazonas">Amazonas</option>
                    <option value="Vichada">Vichada</option>
                    <option value="Vaupés">Vaupés</option>
                    <option value="Guainía">Guainía</option>
                  </select>
                </div>
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm">
                    Country :<span className="px-1 text-red-500">*</span>
                  </div>
                  <select
                    name="country"
                    value={AgencyProfileDetails?.country}
                    onChange={onInputChange}
                    className="round w-full font-medium !text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  >
                    <option value="">Select country</option>
                    <option value="Columbia">Columbia</option>
                    {/* <option value="United States">United States</option> */}
                  </select>
                </div>
              </div>

              {/* -------- Mailing Address -------- */}
              <div className="font-semibold text-[#E5002A] my-5 md:my-8">
                Mailing Address
              </div>

              <div className="my-2 flex items-center">
                <label
                  htmlFor="addressCheck"
                  className="font-semibold text-xs md:text-sm mr-1"
                >
                  Same as above
                </label>
                <input
                  type="checkbox"
                  name="checkAbove"
                  id="addressCheck"
                  onChange={onInputChange}
                />
              </div>
              <div className="font-medium text-[#737373] text-xs md:text-sm mb-4">
                These fields will only display on your specialized agency
                website. To update your agency billing address please contact
                Customer Care.
              </div>
              <div className="">
                <div className="font-medium text-[#171717] text-xs md:text-sm">
                  Street/P.O. Box :
                </div>
                <input
                  type="text"
                  value={AgencyProfileDetails?.mailing_address_street}
                  name="mailing_address_street"
                  onChange={onInputChange}
                  placeholder="Enter street"
                  className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-3"
                />
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-4 mt-4 md:mt-6">
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm ">
                    Suburb/Area :
                  </div>

                  <input
                    type="text"
                    value={AgencyProfileDetails?.mailing_address_suburb_area}
                    name="mailing_address_suburb_area"
                    onChange={onInputChange}
                    placeholder="Enter suburb area"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                </div>
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm">
                    Postcode :
                  </div>
                  <input
                    type="number"
                    value={AgencyProfileDetails?.mailing_address_postcode}
                    name="mailing_address_postcode"
                    onChange={onInputChange}
                    placeholder="Enter postcode"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-4 mt-4 md:mt-6">
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm ">
                    State/Region :<span className="px-1 text-red-500">*</span>
                  </div>
                  <select
                    name="mailing_address_state_region"
                    value={AgencyProfileDetails?.mailing_address_state_region}
                    onChange={onInputChange}
                    className="round w-full font-medium !text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  >
                    <option value="">Select state / region</option>
                    <option value="Bogotá">Bogotá</option>
                    <option value="Antioquia">Antioquia</option>
                    {/* <option value="Western Australia">Western Australia</option> */}
                    <option value="Valle del Cauca">Valle del Cauca</option>
                    <option value="Cundinamarca">Cundinamarca</option>
                    <option value="Atlántico">Atlántico</option>
                    <option value="Santander">Santander</option>
                    <option value="Bolívar">Bolívar</option>
                    <option value="Nariño">Nariño</option>
                    <option value="Córdoba">Córdoba</option>
                    <option value="Tolima">Tolima</option>
                    <option value="Cauca">Cauca</option>
                    <option value="Norte de Santander">
                      Norte de Santander
                    </option>
                    <option value="Boyacá">Boyacá</option>
                    <option value="Magdalena">Magdalena</option>
                    <option value="Huila">Huila</option>
                    <option value="Cesar">Cesar</option>
                    <option value="Caldas">Caldas</option>
                    <option value="Meta">Meta</option>
                    <option value="La Guajira">La Guajira</option>
                    <option value="Risaralda">Risaralda</option>
                    <option value="Sucre">Sucre</option>
                    <option value="Quindío">Quindío</option>
                    <option value="Chocó">Chocó</option>
                    <option value="Caquetá">Caquetá</option>
                    <option value="Casanare">Casanare</option>
                    <option value="Putumayo">Putumayo</option>
                    <option value="Arauca">Arauca</option>
                    <option value="Guaviare">Guaviare</option>
                    <option value="San Andrés y Providencia">
                      San Andrés y Providencia
                    </option>
                    <option value="Amazonas">Amazonas</option>
                    <option value="Vichada">Vichada</option>
                    <option value="Vaupés">Vaupés</option>
                    <option value="Guainía">Guainía</option>
                  </select>
                </div>
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm">
                    Country :<span className="px-1 text-red-500">*</span>
                  </div>
                  <select
                    name="mailing_address_country"
                    value={AgencyProfileDetails?.mailing_address_country}
                    onChange={onInputChange}
                    className="round w-full font-medium !text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  >
                    <option value="">Select country</option>
                    <option value="Columbia">Columbia</option>
                    {/* <option value="Australia">Australia</option>
                    <option value="United States">United States</option> */}
                  </select>
                </div>
              </div>
              {/* -------- Contact Info -------- */}
              <div className="font-semibold text-[#E5002A] my-5 md:my-8">
                Contact Info
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-4 mt-4 md:mt-6">
                {/* <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm ">
                    Fax :
                  </div>
                  <input
                    type="text"
                    value={AgencyProfileDetails?.fax}
                    name="fax"
                    onChange={onInputChange}
                    placeholder="Enter fax"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                  <div className="text-[#737373] text-xs md:text-sm p-2 ">
                    If supplying a fax number, please include prefix as above.
                  </div>
                </div> */}
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm">
                    Phone : <span className="px-1 text-red-500">*</span>
                  </div>
                  <input
                    type="number"
                    value={AgencyProfileDetails?.phone}
                    name="phone"
                    onChange={onInputChange}
                    placeholder="Enter phone"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                  {/* <div className="text-[#737373] text-xs md:text-sm p-2 ">
                    (Please include prefix, eg. 03 9555 1777 for Australian
                    Agents, or 9 2673656 for New Zealand Agents.)
                  </div> */}
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-4 mt-4 md:mt-6">
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm ">
                    Email : <span className="px-1 text-red-500">*</span>
                  </div>
                  <input
                    type="email"
                    value={AgencyProfileDetails?.email}
                    name="email"
                    onChange={onInputChange}
                    placeholder="Enter email"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                </div>
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm">
                    Web :
                  </div>
                  <input
                    type="text"
                    value={AgencyProfileDetails?.web}
                    name="web"
                    onChange={onInputChange}
                    placeholder="Enter web"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-4 mt-4 md:mt-6">
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm ">
                    Facebook Page :
                  </div>
                  <div className="w-full flex flex-row justify-start items-center gap-2 mt-3">
                    <input
                      type="text"
                      value={AgencyProfileDetails?.facebook_page}
                      name="facebook_page"
                      onChange={onInputChange}
                      placeholder="Enter facebook page"
                      className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5"
                    />
                    {/* <img
                      src={questionMark}
                      alt="icon"
                      className="w-5 cursor-pointer"
                    /> */}
                  </div>
                  {/* <div className="text-[#3B8FD4] font-semibold text-xs md:text-sm p-2 cursor-pointer">
                    More about Facebook
                  </div> */}
                </div>
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm">
                    Twitter Profile URL :
                  </div>
                  <div className="w-full flex flex-row justify-start items-center gap-2 mt-3">
                    <input
                      type="text"
                      value={AgencyProfileDetails?.twitter_profile_url}
                      name="twitter_profile_url"
                      onChange={onInputChange}
                      placeholder="Enter twitter profile url"
                      className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5"
                    />
                    {/* <img
                      src={questionMark}
                      alt="icon"
                      className="w-5 cursor-pointer"
                    /> */}
                  </div>
                  {/* <div className="text-[#3B8FD4] font-semibold text-xs md:text-sm p-2 cursor-pointer">
                    More about Twitter
                  </div> */}
                </div>
              </div>
              {/* -------- Wbsite Link -------- */}
              <div className="font-semibold text-[#E5002A] my-5 md:my-8">
                These fields will only display on your specialised agency
                website
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-4 mt-4 md:mt-6">
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm ">
                    Agency Name :
                  </div>
                  <input
                    type="text"
                    value={AgencyProfileDetails?.principal_name}
                    name="principal_name"
                    onChange={onInputChange}
                    placeholder="Enter your principal name"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                </div>
                <div className="w-full">
                  <div className="font-medium text-[#171717] text-xs md:text-sm">
                    Display Email :
                  </div>
                  <input
                    type="text"
                    value={AgencyProfileDetails?.display_email}
                    name="display_email"
                    onChange={onInputChange}
                    placeholder="Enter your display email"
                    className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-[28px] py-3 px-5 mt-3"
                  />
                </div>
              </div>
              {/* -------- Office Description -------- */}
              {/* <div className="font-semibold text-[#E5002A] my-5 md:my-8">
                Office Description
              </div> */}

              {/* <div className="font-medium text-[#737373] text-xs md:text-sm lg:text-base">
                The description is used as part of the{`  `}
                <span className="text-[#3B8FD4] font-semibold cursor-pointer">
                  Feature Agent Package
                </span>
                {`  `}& for Platinum subscribers.
              </div> */}

              {/* <div className="text-[#262626] text-xs md:text-sm lg:text-base font-semibold cursor-pointer">
                Office Description
              </div> */}

              {/* <div className="mt-4 md:mt-6">
                <div className="font-medium text-[#171717] text-xs md:text-sm">
                  Limited to 700 characters. No HTML tags.
                </div>
                <textarea
                  rows={6}
                  type="text"
                  value={AgencyProfileDetails?.office_description}
                  name="office_description"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setAgencyProfileDetails({
                      ...AgencyProfileDetails,
                      [name]: value,
                    });
                    setTextAreaLength(value.split("").length);
                  }}
                  placeholder="Enter office description"
                  className="w-full font-medium text-[#737373] text-xs md:text-sm  outline-none border border-[#E5E5E5] rounded-xl py-3 px-5  mt-3"
                />
                {textAreaLength < 701 ? (
                  <div className="font-medium text-[#171717] text-xs px-2">
                    Characters left: {`  `}
                    {textAreaLength !== undefined
                      ? `${textAreaLength - 700}`
                      : 0}
                  </div>
                ) : null}
              </div> */}
              {/* -------- Buton -------- */}
              <div className="border-b-2 border-[#E5E5E5] my-4 md:my-6" />
              <div className="flex flex-row justify-start items-center">
                <button
                  className="bg-[#E5002A] text-white font-medium text-sm px-5 py-3 rounded-3xl shadow  outline-none"
                  onClick={EditAgencyProfile}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>

          {/* --------- Right Part --------- */}

          <div className="w-full xl:w-[25%] bg-white rounded-xl">
            <div className="bg-[#E5002A] text-white font-semibold text-sm md:text-base lg:text-lg rounded-t-xl p-5">
              Your Profile
            </div>
            {ProfileData?.length > 0 &&
              ProfileData?.map((i, index) => (
                <div
                  key={index}
                  className="border-b-2 border-[#F5F5F5] text-[#404040] text-sm md:text-sm lg:text-base py-4 px-5 "
                >
                  {i}
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout1>
  );
};

export default AgencyProfile;
