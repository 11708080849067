import React, { useEffect, useState } from 'react'
import Layout1 from '../../Layouts/Layout1'
import axiosInstanceAuth from '../../apiInstances/axiosInstanceAuth';
import { toast } from 'react-toastify';

const Appraisal = () => {
  const [appraisalDetails, setAppraisalDetails] = useState([])
  useEffect(() => {
    getAppraisalDetails()
  }, [])
  const getAppraisalDetails = async () => {
    await axiosInstanceAuth
      .get('/get/appraisal')
      .then((res) => {
        if (res.data.status) {
          setAppraisalDetails(res.data.data)
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((error) => {
        console.log("error", error)
        toast.error(error.message)
      })
  }
  return (
    <Layout1>
      <div className="min-w-[900px] bg-white rounded-2xl shadow-md my-10">
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Date</th>
              <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Full Name</th>
              <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Email</th>
              <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Mobile No.</th>
              <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Type</th>
              <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Reason</th>
            </tr>
          </thead>
          <tbody>
            {appraisalDetails?.length > 0 &&
              appraisalDetails.map((d, index) => (
                <tr key={index} className="border-t border-t-[#D4D4D4]">
                  <td className="p-4 text-[#262626] font-semibold text-sm lg:text-base">
                    {d.createdAt.split("T")[0]}
                  </td>
                  <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                    {d.first_name} {(d?.first_name?.length + d.last_name.length) > 12 ? <div>{d.last_name}</div> : d.last_name}
                  </td>
                  <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                    {d.email}
                  </td>
                  <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                    {d.mobile_no}
                  </td>
                  <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                    {d.type}
                  </td>
                  <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                    {d.reason}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Layout1>
  )
}

export default Appraisal